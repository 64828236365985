import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subject, timer } from 'rxjs';
import { distinctUntilChanged, map, takeUntil, takeWhile } from 'rxjs/operators';
import { normalizeAuctionStartDatetime } from 'src/app/const';

import { Lot, LotSource, LotTypeEnum } from '../../graphql/service/graphql-auto-main-service';
import { LotWatchRequestFormModalComponent } from './components/lot-watch-request-form-modal/lot-watch-request-form-modal.component';

@Component({
  selector: 'app-lot-watch-request-button',
  templateUrl: './lot-watch-request-button.component.html',
  styleUrls: ['./lot-watch-request-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotWatchRequestButtonComponent implements OnInit, OnDestroy {
  @Input() savedLot: Lot;
  @Input() lotType: LotTypeEnum;
  @Input() lotSource: LotSource;
  @Input() isLotSanctioned = false;
  @Input() fullSize = false;
  @Output() lotShared = new EventEmitter<void>();

  @HostBinding('style.display')
  display: 'none' | 'inline-block' = 'none';

  private supportedAuctions = ['uss tokyo', 'uss yokohama'];

  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly translate: TranslateService,
    private readonly modalService: NzModalService,
  ) {}

  ngOnInit(): void {
    const isAuctionSupported = this.supportedAuctions.includes(
      this.savedLot.auctionName.toLowerCase().trim(),
    );

    if (isAuctionSupported && !this.isLotSanctioned) {
      const auctionStart = normalizeAuctionStartDatetime(this.savedLot.auctionDatetime);
      timer(0, 1000)
        .pipe(
          map(() => DateTime.now() >= auctionStart.minus({ hour: 2 })),
          distinctUntilChanged(),
          takeWhile((timeLimitReached) => !timeLimitReached, true),
          takeUntil(this.destroy$),
        )
        .subscribe((timeLimitReached) => {
          this.display = timeLimitReached ? 'none' : 'inline-block';
          this.cdr.markForCheck();
        });
    } else {
      this.display = 'none';
    }
  }

  openWatchRequestModal(): void {
    this.modalService.create({
      nzTitle: this.translate.instant('WATCH_REQUEST.TITLE'),
      nzContent: LotWatchRequestFormModalComponent,
      nzComponentParams: {
        lot: this.savedLot,
        lotType: this.lotType,
        lotSource: this.lotSource,
        lotShared: this.lotShared,
      },
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
