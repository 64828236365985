import { MutationHandlingServiceV2, QueryHandlingService } from '@akebono/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  first,
  map,
  shareReplay,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import {
  Lot,
  LotSource,
  LotTypeEnum,
} from 'src/app/modules/graphql/service/graphql-auto-main-service';
import {
  LotWatchRequestDataGQL,
  RequestPhotoFromAuctionGQL,
  VideoWatchTypeEnum,
} from 'src/app/modules/graphql/service/graphql-shared-familiar-service';
import { LotShareService } from 'src/app/services/lot-share.service';

@Component({
  selector: 'app-lot-watch-request-form-modal',
  templateUrl: './lot-watch-request-form-modal.component.html',
  styleUrls: ['./lot-watch-request-form-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotWatchRequestFormModalComponent implements OnInit, OnDestroy {
  @Input() lot: Lot;
  @Input() lotType: LotTypeEnum;
  @Input() lotSource: LotSource;
  @Input() lotShared: EventEmitter<void>;

  loading$: Observable<boolean>;
  creating$ = of(false);

  lotLink$: Observable<string>;
  price$: Observable<number>;
  alreadyCreated$: Observable<boolean>;
  files$: Observable<string[]>;
  hasFiles$: Observable<boolean>;

  comment = '';

  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly modal: NzModalRef,
    private readonly qhs: QueryHandlingService,
    private readonly mhs: MutationHandlingServiceV2,
    private readonly lotShareService: LotShareService,
    private readonly lotWatchRequestDataGQL: LotWatchRequestDataGQL,
    private readonly requestPhotoFromAuctionGQL: RequestPhotoFromAuctionGQL,
  ) {}

  ngOnInit(): void {
    const shareLotResult = this.lotShareService.share(this.lot, this.lotType, this.lotSource);

    this.lotLink$ = shareLotResult.link$.pipe(shareReplay(1));
    const dataQueryRef$ = this.lotLink$.pipe(
      map((lotLink) => this.qhs.fetch(this.lotWatchRequestDataGQL, { lotLink }, 'network-only')),
      shareReplay(1),
    );

    const data$ = dataQueryRef$.pipe(switchMap(({ data }) => data));
    this.price$ = data$.pipe(map((data) => data.serviceRequestPrice || 0));

    const serviceRequest$ = data$.pipe(map((data) => data.serviceRequestAuctionLotByUser));
    this.alreadyCreated$ = serviceRequest$.pipe(map((request) => !!request));
    this.files$ = serviceRequest$.pipe(
      map((request) => request.files || []),
      map((files) => files.map((file) => file.fullPathForFile)),
    );
    this.hasFiles$ = this.files$.pipe(map((files) => !!files.length));

    this.loading$ = combineLatest([
      shareLotResult.loading$,
      dataQueryRef$.pipe(
        switchMap(({ loading }) => loading),
        startWith(true),
      ),
    ]).pipe(
      map((loadings) => loadings.some((loading) => loading)),
      distinctUntilChanged(),
      shareReplay(1),
    );

    if (!this.lotShareService.getLink(this.lot)) {
      this.lotLink$.pipe(first(), takeUntil(this.destroy$)).subscribe(() => this.lotShared.emit());
    }
  }

  async createRequest(): Promise<void> {
    const lotLink = await this.lotLink$.toPromise();
    const mutationRef = this.mhs.mutate(this.requestPhotoFromAuctionGQL, {
      input: {
        lotLink,
        type: VideoWatchTypeEnum.Auction,
        lotData: `${this.lot.code} - ${this.lot.auctionName}`,
        comment: this.comment,
      },
    });

    this.creating$ = mutationRef.loading;
    mutationRef.data.pipe(first(), takeUntil(this.destroy$)).subscribe(() => this.close());
  }

  close(): void {
    this.modal.destroy();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
