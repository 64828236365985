<nz-skeleton [nzLoading]="loading$ | async" [nzActive]="true" [nzParagraph]="{ rows: 5 }">
  <ng-container *ngIf="alreadyCreated$ | async; else createRequestForm">
    <nz-alert
      *ngIf="(hasFiles$ | async) === false"
      nzType="warning"
      [nzMessage]="'WATCH_REQUEST.ALREADY_CREATED' | translate"
    ></nz-alert>
    <nz-image-group *ngIf="hasFiles$ | async">
      <div class="gallery">
        <img *ngFor="let file of files$ | async" nz-image width="150px" [nzSrc]="file" />
      </div>
    </nz-image-group>
  </ng-container>

  <ng-template #createRequestForm>
    <section>
      <nz-alert [nzMessage]="alertContent">
        <ng-template #alertContent>
          <span
            [innerHTML]="'WATCH_REQUEST.ALERT' | translate: { price: price$ | async | number }"
          ></span>
        </ng-template>
      </nz-alert>
    </section>
    <section>
      <textarea
        nz-input
        [(ngModel)]="comment"
        [rows]="4"
        [placeholder]="'WATCH_REQUEST.COMMENT' | translate"
      ></textarea>
    </section>
  </ng-template>
</nz-skeleton>

<ng-container *nzModalFooter>
  <button
    nz-button
    nzType="primary"
    [disabled]="!comment"
    [nzLoading]="creating$ | async"
    (click)="createRequest()"
  >
    {{ 'WATCH_REQUEST.SEND' | translate }}
  </button>
  <button nz-button (click)="close()">
    {{ 'CANCEL' | translate }}
  </button>
</ng-container>
