export const hybrids = [
  { company: 'Audi', model: 'A3', frame: '8VCUK, GYDLA' },
  { company: 'Audi', model: 'A3 SEDAN', frame: '8VCUK, GYDLA' },
  { company: 'Audi', model: 'A3 SPORTBACK E-TRON', frame: '8VCUK' },
  {
    company: 'Audi',
    model: 'A4',
    frame: '8WDEZ, 8WDETF, 8WDTPF, 8WDEM, 8WDDWF, 8WDTPF, 8WDEM, 8WDDWF, 8WDDWF',
  },
  { company: 'Audi', model: 'A5', frame: 'F5DTPF, F5DDWF, F5DEZL, F5DTPL, F5DDWL, F5DDWL' },
  {
    company: 'Audi',
    model: 'A6',
    frame: '4GCHJS, F2DFBF, F2DKNF, F2DKNF, F2DLZF, F2DFBF, F2DKNF, F2DKNF, F2DLZF',
  },
  { company: 'Audi', model: 'A7', frame: 'F2DFBS, F2DKNS, F2DKNS, F2DLZS' },
  { company: 'Audi', model: 'A8', frame: '4HCHJA, 4HCHJA, F8CZSF, F8CXYF, F8CZSF, F8CXYF' },
  { company: 'Audi', model: 'Q3 Sportback', frame: 'F3DFY' },
  { company: 'Audi', model: 'Q3', frame: 'F3DFY' },
  { company: 'Audi', model: 'Q4 Sportback e-tron', frame: 'FZEBJ' },
  { company: 'Audi', model: 'Q4 e-tron', frame: 'FZEBJ' },
  { company: 'Audi', model: 'Q5 Sportback', frame: 'FYDTPS' },
  { company: 'Audi', model: 'Q5', frame: 'FYDTPS, FYDGKS' },
  { company: 'Audi', model: 'Q7', frame: '4MDCBS' },
  { company: 'Audi', model: 'Q8', frame: 'F1DCBS' },
  { company: 'Audi', model: 'RS e-tron GT', frame: 'FWEBGE' },
  { company: 'Audi', model: 'S7', frame: 'F2DKMA' },
  { company: 'Audi', model: 'S8', frame: 'F8CWWF, F8CWWF' },
  { company: 'Audi', model: 'e-tron GT', frame: 'FWEBGS' },
  { company: 'Audi', model: 'e-tron S', frame: 'GEEAV' },
  { company: 'Audi', model: 'e-tron Sportback S', frame: 'GEEAV' },
  { company: 'Audi', model: 'e-tron Sportback', frame: 'GEEASB, GEEAS' },
  { company: 'Audi', model: 'e-tron', frame: 'GEEASB, GEEAS' },
  { company: 'BMW', model: '2 Series', frame: '2C15' },
  { company: 'BMW', model: '2 Series Active Tourer', frame: '2C15, 6Y15' },
  { company: 'BMW', model: '3 Series', frame: '3F30, 8E20, 5X20' },
  { company: 'BMW', model: '5 Series', frame: 'FZ35, JA20P, JA20PH' },
  { company: 'BMW', model: '7 Series', frame: 'YA30, 7D20, 7W30, 7D30, 22EH30' },
  { company: 'BMW', model: 'I3', frame: '8P06, 1Z06, 1Z00, 8P00' },
  { company: 'BMW', model: 'I4', frame: '32AW89, 72AW44' },
  { company: 'BMW', model: 'I7', frame: '52EJ89' },
  { company: 'BMW', model: 'I8', frame: 'I12' },
  { company: 'BMW', model: 'IX', frame: '12CF89A, 12CF89S, 22CF89A, 31CF93A' },
  { company: 'BMW', model: 'IX1', frame: '62EF67' },
  { company: 'BMW', model: 'IX3', frame: '42DU44' },
  { company: 'BMW', model: 'X3', frame: 'TS20, UZ7230' },
  { company: 'BMW', model: 'X5', frame: 'KT20, JU8230S, JU8230A, TA30' },
  { company: 'BMW', model: 'X6', frame: 'GT8230S' },
  { company: 'BMW', model: 'X7', frame: 'TB4230, 32EM44' },
  { company: 'CITROEN', model: 'DS4', frame: 'D415G06H' },
  { company: 'CITROEN', model: 'A CLASS', frame: 'C845G06' },
  { company: 'CITROEN', model: 'C5', frame: 'C845G06' },
  { company: 'HONDA', model: 'Accord', frame: 'CR6, CR5, CR7, CV3' },
  { company: 'HONDA', model: 'CLARITY', frame: 'ZC5, ZC4' },
  { company: 'HONDA', model: 'CR-V', frame: 'RT5, RT6' },
  { company: 'HONDA', model: 'CR-Z', frame: 'ZF1, ZF2' },
  { company: 'HONDA', model: 'Civic', frame: 'ES9, FL4, FD3' },
  { company: 'HONDA', model: 'Fit Shuttle', frame: 'GP2' },
  { company: 'HONDA', model: 'Fit', frame: 'GP1, GP4, GP5, GP6, GR3, GR6, GR4, GR8, DAA-GP5' },
  { company: 'HONDA', model: 'Freed Spike', frame: 'GP3' },
  { company: 'HONDA', model: 'Freed', frame: 'GP3, GB7, GB8' },
  { company: 'HONDA', model: 'Freed+', frame: 'GB7, GB8' },
  { company: 'HONDA', model: 'Freed Hybrid', frame: 'GP3, GB7, GB8' },
  { company: 'HONDA', model: 'Grace', frame: 'GM4, GM5' },
  { company: 'HONDA', model: 'INSIGHT', frame: 'ZE1, ZE2, ZE3, ZE4' },
  { company: 'HONDA', model: 'Jade', frame: 'FR4' },
  { company: 'HONDA', model: 'Legend', frame: 'KC2' },
  { company: 'HONDA', model: 'NSX', frame: 'NC1' },
  { company: 'HONDA', model: 'Odyssey', frame: 'RC4' },
  { company: 'HONDA', model: 'Shuttle', frame: 'GP7, GP8' },
  { company: 'HONDA', model: 'Stepwgn', frame: 'RP5, RP8' },
  { company: 'HONDA', model: 'Vezel', frame: 'RU3, RU4, RV5, RV6' },
  { company: 'HONDA', model: 'ZR-V', frame: 'RZ4, RZ6' },
  { company: 'JEEP', model: 'Renegade', frame: 'BV13' },
  { company: 'Lexus', model: 'CT200h', frame: 'ZWA10' },
  { company: 'Lexus', model: 'CT', frame: 'ZWA10' },
  { company: 'Lexus', model: 'ES300h', frame: 'AXZH10' },
  { company: 'Lexus', model: 'GS300h', frame: 'AWL10' },
  { company: 'Lexus', model: 'GS450h', frame: 'GWS191, GWL10' },
  { company: 'Lexus', model: 'HS250h', frame: 'ANF10' },
  { company: 'Lexus', model: 'IS300h', frame: 'AVE30, AVE35' },
  { company: 'Lexus', model: 'LBX', frame: 'MXPJ10, MXPJ15' },
  { company: 'Lexus', model: 'LC500h', frame: 'GWZ100' },
  { company: 'Lexus', model: 'LM300h', frame: 'AYH36' },
  { company: 'Lexus', model: 'LS500h', frame: 'GVF55, GVF50' },
  { company: 'Lexus', model: 'LS600h', frame: 'UVF45' },
  { company: 'Lexus', model: 'LS600hL', frame: 'UVF46' },
  { company: 'Lexus', model: 'NX300h', frame: 'AYZ10, AYZ15' },
  { company: 'Lexus', model: 'NX350h', frame: 'AAZH20, AAZH25' },
  { company: 'Lexus', model: 'NX450h+', frame: 'AAZH26' },
  { company: 'Lexus', model: 'RC300h', frame: 'AVC10' },
  { company: 'Lexus', model: 'RX350h', frame: 'AALH10' },
  { company: 'Lexus', model: 'RX450h', frame: 'GYL10W, GYL15W, GYL16W, GYL20W, GYL25W' },
  { company: 'Lexus', model: 'RX450h+', frame: 'AALH16' },
  { company: 'Lexus', model: 'RX450hL', frame: 'GYL26W' },
  { company: 'Lexus', model: 'RX500h', frame: 'TALH17' },
  { company: 'Lexus', model: 'UX250h', frame: 'MZAH10, MZAH15' },
  { company: 'MAZDA', model: 'Axela', frame: 'BYEFP' },
  { company: 'MAZDA', model: 'CX-30', frame: 'DMEJ3P, DMFP' },
  { company: 'MAZDA', model: 'CX-60', frame: 'KH5S3P, KH3R3P' },
  { company: 'MAZDA', model: 'Familia', frame: 'NHP160M' },
  { company: 'MAZDA', model: 'Flair Crossover', frame: 'MS52S, MS92S, MS41S' },
  { company: 'MAZDA', model: 'Flair Wagon', frame: 'MM42S, MM53S' },
  { company: 'MAZDA', model: 'Flair', frame: 'MJ44S, MJ95S, MJ55S' },
  { company: 'MAZDA', model: 'MX-30', frame: 'DR8V3P, DREJ3P' },
  { company: 'MAZDA', model: 'Mazda3', frame: 'BPFJ3R, BPEP, BPEK3R, BPFJ3P' },
  { company: 'MAZDA', model: 'RX-7', frame: 'FC3C, FC3S, FD3S, SA22C, FB3S' },
  { company: 'MAZDA', model: 'RX-8', frame: 'SE3P' },
  { company: 'MITSUBISHI', model: 'Delica D:2', frame: 'MB36S, MB46S, MB37S' },
  { company: 'MITSUBISHI', model: 'Delica D2', frame: 'MB36S, MB46S, MB37S' },
  { company: 'MITSUBISHI', model: 'Delica Mini', frame: 'B34A, B37A, B35A, B38A' },
  { company: 'MITSUBISHI', model: 'Dignity', frame: 'BHGY51' },
  { company: 'MITSUBISHI', model: 'Eclipse Cross', frame: 'GL3W' },
  { company: 'MITSUBISHI', model: 'Minicab MiEV', frame: 'U68V, U67V' },
  { company: 'MITSUBISHI', model: 'eK Space', frame: 'B34A, B37A, B35A, B38A' },
  { company: 'MITSUBISHI', model: 'eK Wagon', frame: 'B34W, B37W, B35W, B38W' },
  { company: 'MITSUBISHI', model: 'eK X EV', frame: 'B5AW' },
  { company: 'MITSUBISHI', model: 'i-MiEV', frame: 'HD4WLDD, HA4WLDDB, HA3W' },
  { company: 'Mercedes Benz', model: 'A Class', frame: '177186, 177086' },
  {
    company: 'Mercedes Benz',
    model: 'C Class',
    frame:
      '205047, 205053, 205077, 205078, 205247, 205277, 205278, 205077C, 205078C, 206004C, 206041C, 206042C, 206043C',
  },
  {
    company: 'Mercedes Benz',
    model: 'C Class station wagon',
    frame: '205247, 205277, 205278, 205277C, 205278C, 206204C, 206214C, 206241C',
  },
  {
    company: 'Mercedes Benz',
    model: 'C Class wagon',
    frame: '205247, 205277, 205278, 205277C, 205278C, 206204C, 206214C, 206241C',
  },
  { company: 'Mercedes Benz', model: 'CLS Class', frame: '257359, 257361, 257359, 257361, 257314' },
  {
    company: 'Mercedes Benz',
    model: 'E Class',
    frame:
      '212095C, 213078C, 213077C, 213053C, 213050C, 213061, 213278C, 213277C, 238377C, 238477C, 213078C, 213077C, 213016, 213053, 213061, 213278C, 213277C, 213259, 213261, 238477C, 238461, 238377C, 238359, 238361',
  },
  { company: 'Mercedes Benz', model: 'E Class Station Wagon', frame: '213277C' },
  { company: 'Mercedes Benz', model: 'EQA', frame: '243701, 243701CN, 243701C' },
  { company: 'Mercedes Benz', model: 'EQB', frame: '243601C, 243601, 243612C, 243612' },
  { company: 'Mercedes Benz', model: 'EQC', frame: '293890' },
  { company: 'Mercedes Benz', model: 'EQE', frame: '295121, 295153' },
  { company: 'Mercedes Benz', model: 'EQS SUV', frame: '296624, 296644' },
  { company: 'Mercedes Benz', model: 'EQS', frame: '297123, 297155' },
  {
    company: 'Mercedes Benz',
    model: 'S Class',
    frame:
      '222004, 222104, 222163, 222057, 222173, 222058, 222158, 223169, 223063, 223163, 223076, 223176',
  },
  { company: 'Mercedes Benz', model: 'SL Class', frame: '232450C' },
  { company: 'NISSAN', model: 'Aura', frame: 'FE13, FSNE13' },
  { company: 'NISSAN', model: 'DAYZ', frame: 'B44W, B45W, B46W, B47W, B48W' },
  { company: 'NISSAN', model: 'Fuga', frame: 'HY51' },
  { company: 'NISSAN', model: 'Kicks', frame: 'RP15, P15, SNP15' },
  { company: 'NISSAN', model: 'Kix', frame: 'RP15, P15, SNP15' },
  { company: 'NISSAN', model: 'LEAF', frame: 'AZE0, ZE0, ZE1, AZE1' },
  { company: 'NISSAN', model: 'Murano', frame: 'Z52' },
  { company: 'NISSAN', model: 'Note', frame: 'HE12, SNE12, E13, SNE13' },
  { company: 'NISSAN', model: 'Rogue', frame: 'T32U' },
  { company: 'NISSAN', model: 'Roox', frame: 'B44A, B47A, B45A, B48A' },
  {
    company: 'NISSAN',
    model: 'Serena',
    frame: 'HFC26, HC26, HC27, HFC27, GC27, GFC27, GNC27, GFNC27, GC28, GFC28',
  },
  { company: 'NISSAN', model: 'Skyline', frame: 'HNV37, HV37, HNV37, HV37' },
  { company: 'NISSAN', model: 'Tino', frame: 'PV10' },
  { company: 'NISSAN', model: 'e-NV200', frame: 'ME0' },
  { company: 'PEUGEOT', model: '308', frame: 'P515G06H, P525G06H' },
  { company: 'PEUGEOT', model: '508', frame: 'R85G06H, R85G06H' },
  { company: 'PEUGEOT', model: '3008', frame: 'P515G06H, P525G06H' },
  { company: 'SUBARU', model: 'Crosstrek', frame: 'GUD, GUE' },
  { company: 'SUBARU', model: 'Forester', frame: 'SKE' },
  { company: 'SUBARU', model: 'Impreza', frame: 'GTE, GUD, GUE, GPE' },
  { company: 'SUBARU', model: 'XV', frame: 'GTE, GPE' },
  { company: 'SUZUKI', model: 'Alto', frame: 'HA97S' },
  { company: 'SUZUKI', model: 'Hustler', frame: 'MR92S, MR52S, MR41S' },
  { company: 'SUZUKI', model: 'Ignis', frame: 'FF21S' },
  { company: 'SUZUKI', model: 'Landy', frame: 'SHC26, SGC27, SGNC27, ZWR90C, ZWR95C' },
  { company: 'SUZUKI', model: 'Solio', frame: 'MA36S, MA46S, MA36S, MA37S, MA47S' },
  { company: 'SUZUKI', model: 'Solio Bandit', frame: 'MA36S, MA46S, MA36S, MA37S, MA47S' },
  { company: 'SUZUKI', model: 'Spacia', frame: 'MK42S, MK53S' },
  { company: 'SUZUKI', model: 'Spacia Gear', frame: 'MK53S' },
  { company: 'SUZUKI', model: 'Swift', frame: 'ZC53S, ZD53S, ZC43S' },
  { company: 'SUZUKI', model: 'Wagon R Smile', frame: 'MX91S' },
  { company: 'SUZUKI', model: 'Wagon R', frame: 'MH44S, MH95S, MH55S' },
  { company: 'SUZUKI', model: 'Xbee', frame: 'MN71S' },
  { company: 'SUZUKI', model: 'Escudo', frame: 'YEH1S' },
  { company: 'TOYOTA', model: 'Aqua', frame: 'NHP10, NHP10H, MXPK10, MXPK11, MXPK15, MXPK16' },
  { company: 'TOYOTA', model: 'Auris', frame: 'ZWE186H' },
  { company: 'TOYOTA', model: 'C-HR', frame: 'ZYX10, ZYX11' },
  { company: 'TOYOTA', model: 'Camry', frame: 'AVV50, AXVH70, AXVH75' },
  { company: 'TOYOTA', model: 'Century', frame: 'UWG60, GRG75' },
  { company: 'TOYOTA', model: 'Corolla Axio', frame: 'NKE165' },
  { company: 'TOYOTA', model: 'Corolla Cross', frame: 'ZVG11, ZVG13, ZVG15, ZVG16' },
  { company: 'TOYOTA', model: 'Corolla Fielder', frame: 'NKE165G' },
  { company: 'TOYOTA', model: 'Corolla Sport', frame: 'ZWE211H, ZWE213H' },
  { company: 'TOYOTA', model: 'Corolla Touring', frame: 'ZWE214W, ZWE211W' },
  { company: 'TOYOTA', model: 'Esquire', frame: 'ZRR80G, ZRR85G, ZWR80G, ZWR80' },
  { company: 'TOYOTA', model: 'JPN Taxi', frame: 'NTP10' },
  { company: 'TOYOTA', model: 'MIRAI', frame: 'JPD10, JPD20' },
  { company: 'TOYOTA', model: 'Noah', frame: 'ZWR80W, ZWR90W, ZWR95W' },
  { company: 'TOYOTA', model: 'Prius Alpha', frame: 'ZVW40W, ZVW41W' },
  { company: 'TOYOTA', model: 'Prius PHV', frame: 'ZVW35, ZVW52, MXWH61' },
  {
    company: 'TOYOTA',
    model: 'Prius',
    frame: 'NHW10, NHW11, NHW20, ZVW30, ZVW50, ZVW51, ZVW55, MXWH60, MXWH61, MXWH65, ZVW60, ZVW65',
  },
  { company: 'TOYOTA', model: 'Probox', frame: 'NHP160V' },
  { company: 'TOYOTA', model: 'SAI', frame: 'AZK10' },
  { company: 'TOYOTA', model: 'Sienta', frame: 'NHP170G, MXPL10G, MXPL12G, MXPL15G' },
  { company: 'TOYOTA', model: 'Succeed', frame: 'NHP160V' },
  { company: 'TOYOTA', model: 'Vitz', frame: 'NHP130' },
  { company: 'TOYOTA', model: 'Voxy', frame: 'ZWR80W, ZWR80G, ZWR90W, ZWR95W' },
  { company: 'TOYOTA', model: 'Yaris', frame: 'MXPH10, MXPH15' },
  { company: 'TOYOTA', model: 'Yaris Cross', frame: 'MXPJ10, MXPJ15' },
  { company: 'TOYOTA', model: 'Raize', frame: 'A202A' },
  { company: 'VOLKSWAGEN', model: 'GOLF', frame: 'CDDLAV, CDDFYV, CDDLA, CDDFY, AUCUK' },
  { company: 'VOLKSWAGEN', model: 'PASSAT', frame: '3CDGE, 3CCUK' },
  { company: 'VOLKSWAGEN', model: 'PASSAT VARIANT', frame: '3CDGE, 3CCUK' },
  { company: 'VOLVO', model: 'XC40', frame: 'XB3154XCP' },
  { company: 'DAIHATSU', model: 'ROCKY', frame: 'A202S' },
  { company: 'MINI', model: 'OTHER', frame: 'YU15T, YU15, 22BS15' },
  { company: 'RENAULT', model: 'Arcana', frame: 'LJLH4MH' },
];
