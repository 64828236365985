<div class="content">
  <nz-collapse>
    <nz-collapse-panel [nzActive]="true" [nzHeader]="'FILTER.FILTER' | translate">
      <nz-row [nzGutter]="[10, 40]">
        <nz-col nzXs="24" [nzLg]="9">
          <form nz-form [formGroup]="form" (ngSubmit)="filter()">
            <nz-row [nzGutter]="[5, 5]">
              <nz-col [nzSpan]="12">
                <nz-form-control style="margin: 0">
                  <input nz-input formControlName="company" [placeholder]="'COMPANY' | translate" />
                </nz-form-control>
              </nz-col>
              <nz-col [nzSpan]="12">
                <nz-form-control style="margin: 0">
                  <input nz-input formControlName="model" [placeholder]="'MODEL' | translate" />
                </nz-form-control>
              </nz-col>
              <nz-col [nzSpan]="12">
                <nz-form-control style="margin: 0">
                  <input
                    nz-input
                    formControlName="modelType"
                    [placeholder]="'FRAME_MODEL' | translate"
                  />
                </nz-form-control>
              </nz-col>
              <!-- <nz-col [nzSpan]="12">
                  <nz-form-control style="margin: 0">
                    <input
                      nz-input
                      formControlName="auction"
                      [placeholder]="'LOT_INFO.AUCTION' | translate"
                    />
                  </nz-form-control>
                </nz-col> -->
              <nz-col [nzSpan]="12">
                <nz-form-control style="margin: 0">
                  <input
                    nz-input
                    formControlName="auctionDate"
                    [placeholder]="'LOT_INFO.AUCTION_DATE' | translate"
                  />
                </nz-form-control>
              </nz-col>
              <nz-col [nzSpan]="12">
                <nz-form-control style="margin: 0">
                  <input nz-input formControlName="code" [placeholder]="'LOT' | translate" />
                </nz-form-control>
              </nz-col>
              <nz-col [nzSpan]="12">
                <nz-form-control style="margin: 0">
                  <input
                    nz-input
                    formControlName="color"
                    [placeholder]="'LOT_INFO.COLOR' | translate"
                  />
                </nz-form-control>
              </nz-col>
              <nz-col [nzSpan]="12">
                <nz-form-control style="margin: 0">
                  <input
                    nz-input
                    formControlName="mileageFrom"
                    [placeholder]="'MILEAGE_FILTER.FROM' | translate"
                  />
                </nz-form-control>
              </nz-col>
              <nz-col [nzSpan]="12">
                <nz-form-control style="margin: 0">
                  <input
                    nz-input
                    formControlName="mileageTo"
                    [placeholder]="'MILEAGE_FILTER.TO' | translate"
                  />
                </nz-form-control>
              </nz-col>
              <nz-col [nzSpan]="12">
                <nz-form-control style="margin: 0">
                  <input
                    nz-input
                    formControlName="yearFrom"
                    [placeholder]="'YEAR_FILTER.FROM' | translate"
                  />
                </nz-form-control>
              </nz-col>
              <nz-col [nzSpan]="12">
                <nz-form-control style="margin: 0">
                  <input
                    nz-input
                    formControlName="yearTo"
                    [placeholder]="'YEAR_FILTER.TO' | translate"
                  />
                </nz-form-control>
              </nz-col>
              <nz-col [nzSpan]="24">
                <nz-form-control style="margin: 0">
                  <input
                    nz-input
                    formControlName="rating"
                    [placeholder]="'LOT_INFO.SCORE' | translate"
                  />
                </nz-form-control>
              </nz-col>
              <nz-col [nzSpan]="24">
                <nz-divider></nz-divider>
              </nz-col>
              <nz-col>
                <button nz-button nzType="primary" style="margin-left: 3px">
                  {{ 'FILTER.SEARCH' | translate }}
                </button>
              </nz-col>
              <nz-col>
                <button nz-button type="default" nzType="button" (click)="removeFilter()">
                  {{ 'FILTER.RESET' | translate }}
                </button>
              </nz-col>
            </nz-row>
          </form>
        </nz-col>
        <nz-col nzXs="24" [nzLg]="15">
          <app-filter-arai-kenki-lots></app-filter-arai-kenki-lots>
        </nz-col>
      </nz-row>
    </nz-collapse-panel>
  </nz-collapse>

  <nz-divider></nz-divider>
  <button
    nz-button
    nzType="primary"
    (click)="createLotManually()"
    *akbCheck="['world.akebono.auto.auctioneer']"
  >
    {{ 'MANUAL_LOTS.ADD_LOT' | translate }}
  </button>
  <nz-divider></nz-divider>
  <div class="purchase-statistics">
    <app-user-purchase-statistics></app-user-purchase-statistics>
  </div>
  <nz-table
    #table
    nzTitle="{{ 'TOTAL' | translate }}: {{ data.total }}"
    [nzTotal]="data.total"
    [nzData]="data.nodes"
    [nzPageIndex]="page"
    [nzPageSize]="pageSize"
    [nzLoading]="loadingResult"
    [nzFrontPagination]="false"
    [nzBordered]="true"
    nzPaginationPosition="both"
    nzShowSizeChanger
    class="table"
    nzSize="small"
    (nzPageIndexChange)="loadData($event)"
    (nzPageSizeChange)="changePageSize($event)"
    nzOuterBordered
  >
    <thead>
      <tr>
        <th rowspan="2" nzAlign="center">{{ 'PHOTO' | translate }}</th>
        <th rowspan="2" nzAlign="center">
          <app-sort-component
            (nzSortChange)="sortEvent($event)"
            [sortKey]="'code'"
            [title]="'LOT' | translate"
          >
          </app-sort-component>
        </th>
        <th rowspan="1" nzAlign="center">
          <app-sort-component
            (nzSortChange)="sortEvent($event)"
            [sortKey]="'auctionName'"
            [title]="'LOT_INFO.AUCTION' | translate"
          ></app-sort-component>
        </th>
        <th rowspan="1" nzAlign="center">
          <app-sort-component
            (nzSortChange)="sortEvent($event)"
            [sortKey]="'modelYearEn'"
            [title]="'LOT_INFO.YEAR' | translate"
          >
          </app-sort-component>
        </th>
        <th rowspan="1" nzAlign="center" [ngClass]="'hide-column-small hide-column-medium'">
          <app-sort-component
            (nzSortChange)="sortEvent($event)"
            [sortKey]="'engineVolumeNum'"
            [title]="'LOT_INFO.ENGINE_CC' | translate"
          ></app-sort-component>
        </th>
        <th rowspan="1" nzAlign="center">
          <app-sort-component
            (nzSortChange)="sortEvent($event)"
            [sortKey]="'mileageNum'"
            [title]="'LOT_INFO.MILEAGE' | translate"
          ></app-sort-component>
        </th>
        <th rowspan="1" nzAlign="center">
          <app-sort-component
            (nzSortChange)="sortEvent($event)"
            [sortKey]="'startPriceNum'"
            [title]="'LOT_INFO.START_PRICE' | translate"
          ></app-sort-component>
        </th>
      </tr>
      <tr>
        <th nzAlign="center">
          <app-sort-component
            (nzSortChange)="sortEvent($event)"
            [sortKey]="'auctionDatetime'"
            [title]="'DATE' | translate"
          >
          </app-sort-component>
        </th>
        <th nzAlign="center">
          <app-sort-component
            (nzSortChange)="sortEvent($event)"
            [sortKey]="'modelTypeEn'"
            [title]="'FRAME_MODEL' | translate"
          >
          </app-sort-component>
        </th>
        <th nzAlign="center">
          <app-sort-component
            (nzSortChange)="sortEvent($event)"
            [sortKey]="'colorEn'"
            [title]="'LOT_INFO.COLOR' | translate"
          >
          </app-sort-component>
        </th>
        <th nzAlign="center">
          <app-sort-component
            (nzSortChange)="sortEvent($event)"
            [sortKey]="'ratingEn'"
            [title]="'LOT_INFO.SCORE' | translate"
          >
          </app-sort-component>
        </th>
        <th nzAlign="center">
          <app-sort-component
            (nzSortChange)="sortEvent($event)"
            [sortKey]="'endPriceNum'"
            [title]="'LOT_INFO.SOLD_FOR' | translate"
          >
          </app-sort-component>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of table.data">
        <td nzAlign="center">
          <app-lot-mini-gallery [lot]="item"></app-lot-mini-gallery>
        </td>
        <td nzAlign="center">
          <app-lots-list-lot-link
            [lot]="item"
            [lotType]="item.subgroup"
            [link]="['/manual-lots', 'lot', item.subgroup, item.id]"
            [lotData]="item"
          ></app-lots-list-lot-link>
        </td>
        <td nzAlign="center">
          <app-lot-auction-name [auctionName]="item.auctionName"></app-lot-auction-name> <br />
          {{ item.auctionDatetime | date: 'yyyy-MM-dd H:mm:ss zz':'+0900' }}
        </td>
        <td nzAlign="left" [ngClass]="'hide-column-small hide-column-medium'">
          <span style="color: #6db3eb">{{ item.companyEn }} {{ item.modelEn }}</span>
          <br />
          <span nz-text nzType="danger" *ngIf="item.year > 0">{{ item.year }}</span> {{ item.vin }}
          <br />
          <span nz-text nzType="secondary">{{ item.modelGradeEn }}</span>
        </td>
        <td nzAlign="left">
          <span nz-text nzType="danger">{{ item.transmissionEn }}</span>
          {{ item.engineVolumeNum || '--' }} cc<br />
          <span nz-text nzType="secondary">{{ item.colorEn }}</span>
          <!-- {{item.equip}} -->
        </td>
        <td nzAlign="right">
          {{ item.mileageNum }} hrs <br />
          <span nz-text nzType="warning">{{ item.ratingEn }}</span>
        </td>
        <td nzAlign="right">
          <div>
            {{ item.startPriceNum | currency: 'JPY':'symbol-narrow' }}
            <br />
            {{ item.endPriceNum | currency: 'JPY':'symbol-narrow' }}
          </div>
          <app-lot-place-bid-button-dumb
            [lot]="item"
            [lotType]="'auto'"
            [lotSource]="'aleado'"
          ></app-lot-place-bid-button-dumb>
        </td>
      </tr>
    </tbody>
  </nz-table>
</div>
